module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"gatsby-starter-default","short_name":"starter","start_url":"/","background_color":"#4385F4","theme_color":"#4385F4","display":"minimal-ui","icon":"src/assets/img/ico.svg","cache_busting_mode":"query","include_favicon":true,"legacy":true,"theme_color_in_head":true,"cacheDigest":"8fc9fe42a4f8c631e4aba0e98fe05e0e"},
    },{
      plugin: require('../node_modules/gatsby-plugin-matomo/gatsby-browser.js'),
      options: {"plugins":[],"siteId":"1","matomoUrl":"https://matamo.super-productivity.com/","siteUrl":"https://super-productivity.com/","requireConsent":false,"disableCookies":true,"cookieDomain":"*.super-productivity.com","dev":false},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
